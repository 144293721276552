// Author: Matthew Leopold

import "./App.css";
import CenterMode from "./Carousel";
import Card from "./Card";
import React from "react";
import { CardResume } from "./Card";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Left-header">
          <h1>Matthew Leopold</h1>
        </div>
        <div className="Right-header">
          <a href="https://www.linkedin.com/in/matthew-leopold-b90686227/">
            <img src="linkedin.svg" className="Linkedin-logo" alt="linkedin" />
          </a>
          <a href="https://github.com/H-137">
            <img src="github.svg" className="Github-logo" alt="github" />
          </a>
        </div>
      </header>
      <div className="About-me">
        <Card
          hover={false}
          content={
            <>
              <h1> About Me</h1>
              <p style={{ width: "75%" }}>
                Hello! My name is Matthew Leopold. I’m currently a sophomore at
                Boston College studying Computer Science (B.S.). I aspire to be
                a full-stack developer. Check out the rest of the site to see my
                accomplishments and projects. <br />
                <br /> If you would like to get in touch with me,{" "}
                <a
                  style={{ color: "grey" }}
                  href="mailto:contact@matt-leopold.com"
                >
                  email me
                </a>
                .
              </p>
            </>
          }
          image={"me.jpg"}
        ></Card>
      </div>
      <h1> Recent Projects </h1>
      <CenterMode />
      <div className="Resume-div">
        <h1> Resume </h1>
        <CardResume
          contents={
            <iframe
              className="Resume"
              src="Resume - Web.pdf"
              title="resume"
              width="90%"
              height="800px"
            ></iframe>
          }
        ></CardResume>
      </div>
      <h1> Full Project List </h1>
      <div className="FullProjectList">
        <div className="card-slider-container">
          <div className="card-slider-container">
            <Card
              content={
                <>
                  <h1> Hack the Heights 2024 </h1>
                  <p style={{ width: "75%" }}>
                    Hack the Heights is a 24-hour hackathon hosted by the Boston
                    College Computer Science Society. This year, my team and I
                    created a tool to help Newton residents find open parking in
                    our neighborhood. We used computer vision to analyze images
                    of parking lots to determine the number of free and open
                    spots. We then created a website to direct individuals to
                    the closest open lot to their destination.
                  </p>
                </>
              }
              image={"hackHeights.png"}
              url={"https://devpost.com/software/project_parking"}
            />
          </div>
          <div className="card-slider-container">
          <Card
            content={
              <>
                <h1> Inherit the Wind</h1>
                <p style={{ width: "75%" }}>
                  This fall I designed for BC Dramatic Society's production of
                  Inherit the Wind. As the light designer, I first had to create
                  a vision for the lighting, then create detailed plans on how
                  to hang the lights and focus them on the stage. Finally, I
                  programmed the cues throughout the show. As an additional
                  challenge for myself, I decided to use LED strip tape that I
                  had to wire together with a power supply and DMX controller
                  myself. Check out a picture from the production!
                </p>
              </>
            }
            image={"Inherit.jpeg"}
          />
        </div>
          <Card
            content={
              <>
                <h1> Bridge2AI Summer Program </h1>
                Over the 2024 summer, I was a participant in the inaugural
                Bridge2AI summer program, which investigated how AI could
                analyze voice and be a biomarker for health. I worked at Weill
                Cornell Medicine alongside a team of computer scientists and med
                school students. The program culminated in a hackathon at the
                end of the summer where my team and I created a voice
                de-identification model, which we verified using a KNN model.
                This was an incredible experience and taught me a lot about
                working with teams and maintaining a project between different
                members of a team. In addition, I got experience using PyTorch
                to create AI models for both synthesis and analysis.
              </>
            }
            image={"bridge2.jpg"}
            url={
              "https://eipm.weill.cornell.edu/news/welcome-inaugural-bridge-2ai-voice-summer-school-class"
            }
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>Cat-Jam</h1>
                Cat-Jam is a side-scrolling Unity runner game with voxel
                graphics. My original concept was in a 2-D pixel style, but when
                I decided to transition to 3-D I wanted to continue to use the
                sprites I already made. This new format introduced me to voxel
                art, 3 dimensional pixel art. This was my first dive into Unity
                3-D, and the new challenges it brought.
              </>
            }
            image={"CatJamGameplay.png"}
            url="https://cat.matt-leopold.com"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>Sudoku Solver</h1>I created a program to solve sudoku
                puzzles in C. In its current form, the program is able to solve
                any puzzle that does not use strategies that require guess and
                check through depth first search. Making this project in C
                definitely made it more difficult, but I compared the results
                against a Python equivalent and this was about 10x faster!
              </>
            }
            image={"sudoku.png"}
            url={"https://github.com/H-137/Sudoku"}
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>LED Matrix Tool</h1>
                This is a tool I created to test out different patterns for a
                physical LED matrix I’m planning to make. The tool was written
                in C++ and is using SFML to render the LED light elements. Each
                button at the bottom of the screen creates a different effect
                including a snake, a smooth color wipe, and a randomizer that
                sets all colors to be random.
              </>
            }
            image="ledMatrix.png"
            url="https://github.com/H-137/Matrix-LED-Planning-with-SFML"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1> Snake </h1>
                This is a snake game fittingly made in python! I used pygame
                which uses a very different structure vs. unity which I’m more
                familiar with.
              </>
            }
            image={"snake.png"}
            url="https://github.com/H-137/Snake"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>C Memory Allocator</h1>
                For class last year we had to create a clone of the malloc tool
                in C that allocates/de-allocates storage on the heap. This
                project taught me a lot about how storage works in the life of a
                C program and how to interact with low level structures.
              </>
            }
            image={"malloc.jpg"}
            url="https://github.com/H-137/Malloc-clone"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>Ridge Sailing Website</h1>I created the Ridge High School
                Sailing Website during the RIdgehacks 2023 Hackathon. I created
                it with only HTML, Javascript, and CSS. The site helps connect
                potential sailors to the team and gives current sailors access
                to race scores.
              </>
            }
            image={"sailing.png"}
            url="https://sailing.matt-leopold.com"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>Pong</h1>
                This is a pong game made in Unity. It was my first project
                working on game design. Complete with both a multiplayer and
                single player mode and pixelated explosions.
              </>
            }
            image={"pong.png"}
            url="https://pong.matt-leopold.com"
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>HTML Bubble Wrap</h1>I made this bubble wrap simulator in
                HTML and CSS. You can scroll and pop bubbles infinitely! It also
                has music and sound effects.
              </>
            }
            image={"Pop.png"}
            url="https://pop.matt-leopold.com"
          />
        </div>
      </div>
      <div className="Footer" style={{ padding: "20px" }}>
        <p> © 2024 Matthew Leopold </p>
      </div>
    </div>
  );
}
export default App;
