import React from "react";
import Slider from "react-slick";
import Card from "./Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

function CenterMode() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    bottomPadding: "100px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  return (
    <div className="Slider-container">
      <Slider {...settings}>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1> Hack the Heights 2024 </h1>
                <p style={{ width: "75%" }}>
                  Hack the Heights is a 24-hour hackathon hosted by the Boston
                  College Computer Science Society. This year, my team and I
                  created a tool to help Newton residents find open parking in
                  our neighborhood. We used computer vision to analyze images of
                  parking lots to determine the number of free and open spots.
                  We then created a website to direct individuals to the closest
                  open lot to their destination.
                </p>
              </>
            }
            image={"hackHeights.png"}
            url={"https://devpost.com/software/project_parking"}
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1> Inherit the Wind</h1>
                <p style={{ width: "75%" }}>
                  This fall I designed for BC Dramatic Society's production of
                  Inherit the Wind. As the light designer, I first had to create
                  a vision for the lighting, then create detailed plans on how
                  to hang the lights and focus them on the stage. Finally, I
                  programmed the cues throughout the show. As an additional
                  challenge for myself, I decided to use LED strip tape that I
                  had to wire together with a power supply and DMX controller
                  myself. Check out a picture from the production!
                </p>
              </>
            }
            image={"Inherit.jpeg"}
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1> Bridge2AI Summer Program </h1>
                Over the 2024 summer, I was a participant in the inaugural
                Bridge2AI summer program, which investigated how AI could
                analyze voice and be a biomarker for health. I worked at Weill
                Cornell Medicine alongside a team of computer scientists and med
                school students. The program culminated in a hackathon at the
                end of the summer where my team and I created a voice
                de-identification model, which we verified using a KNN model.
                This was an incredible experience and taught me a lot about
                working with teams and maintaining a project between different
                members of a team. In addition, I got experience using PyTorch
                to create AI models for both synthesis and analysis.
              </>
            }
            image={"bridge2.jpg"}
            url={
              "https://eipm.weill.cornell.edu/news/welcome-inaugural-bridge-2ai-voice-summer-school-class"
            }
          />
        </div>
        <div className="card-slider-container">
          <Card
            content={
              <>
                <h1>LED Matrix Tool</h1>
                This is a tool I created to test out different patterns for a
                physical LED matrix I’m planning to make. The tool was written
                in C++ and is using SFML to render the LED light elements. Each
                button at the bottom of the screen creates a different effect
                including a snake, a smooth color wipe, and a randomizer that
                sets all colors to be random.
              </>
            }
            image="ledMatrix.png"
            url="https://github.com/H-137/Matrix-LED-Planning-with-SFML"
          />
        </div>
      </Slider>
    </div>
  );
}

export default CenterMode;
